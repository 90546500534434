<template>
  <v-row class="pt-8 fill-height">
    <v-col>
      <v-sheet height="1200" width="99%" class="pl-2">
        <v-row>
          <v-col cols="12" sm="12">
            <h4 class="text-h4 pl-2">Search Planned Jobs</h4>
            <v-row class="pt-4">
              <v-col cols="12" sm="3">
                <v-text-field
                  @keyup="searchApiDebounced"
                  v-model="client"
                  label="Search Client"
                  solo
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  @keyup="searchApiDebounced"
                  v-model="jobNumber"
                  label="Search Job Number"
                  solo
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  @keyup="searchApiDebounced"
                  v-model="jobSite"
                  label="Search Site"
                  solo
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-btn
                  @click="clearSearch"
                  color="orange darken-2"
                  height="48"
                  >Clear Search
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="foundJobs.length > 0">
          <v-col cols="12" sm="8">
            <v-card class="relative" v-for="(job, index) in foundJobs" :key="index">
              <v-icon v-if="job.day" class="job-search-day-night">mdi-weather-sunny</v-icon>
              <v-icon v-else class="job-search-day-night">mdi-moon-waning-crescent</v-icon>
              <p class="pa-3">
                <span class="text-h6">Job number: {{ job.jobNumber }}</span><br />
                {{ job.clientName }}<br />
                {{ job.jobAddress }}<br />
                <strong>{{ job.date | prettyDate }}<br /></strong>
                <strong>
                  <span v-if="job.day">Day</span>
                  <span v-else>Night</span>
                </strong>
                <br />
                {{ job.vehicleReg }}<br />
                Chargehand: {{ job.chargehandName }}<br />
                Worker 1: {{ job.worker2Name }}<br />
                <span v-if="job.worker3Name">Worker 3{{ job.worker3Name }}<br /></span>
                <span v-if="job.worker4Name">Worker 4{{ job.worker4Name }}</span>
              </p>
              <v-btn
                :href="`${$store.state.domain}/job_cards/jobCard/${job.jobCardId}`"
                class="job-search-job-card"
                target="blank"
                color="orange darken-2">
                Job Card
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SearchJobs',
  data() {
    return {
      client: '',
      jobNumber: '',
      jobSite: '',
      foundJobs: [],
      timeout: null,
    };
  },
  computed: {
    plannedJobs() {
      return this.$store.state.plannedJobs;
    },
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    searchApiDebounced() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchApi();
      }, 400);
    },
    searchApi() {
      if (this.client.length > 3 || this.jobNumber.length > 2 || this.jobSite.length > 3) {
        this.foundJobs = [];
        const postData = {};
        postData.client = this.client;
        postData.jobNumber = this.jobNumber;
        postData.jobSite = this.jobSite;
        postData.client = this.client;
        axios.post(`/plannedJobs/search.json?token=${this.token}`, postData)
          .then((response) => {
            this.foundJobs = response.data.plannedJobs;
          });
      }
    },
    clearSearch() {
      this.foundJobs = [];
      this.client = '';
      this.jobNumber = '';
    },
  },
};
</script>

<style lang="scss">
  .relative {
    position: relative;
  }

  .job-search-day-night {
    position: absolute!important;
    right: 3px;
    top: 3px;
  }

  .job-search-job-card {
    position: absolute;
    right: 3px;
    bottom: 14px;
  }
</style>
