<template>
  <v-dialog
    v-model="dialog"
    width="900"
  >
    <v-card>
      <v-icon
        @click="closeDialog"
        large
        color="orange darken-2"
        class="float-right">
        mdi-close
      </v-icon>
      <v-card-title>
        The following vehicles are assigned to jobs
        <span v-if="today === true">&nbsp;today</span>
        <span v-else>&nbsp;yesterday</span>,
        but have not yet been checked.
      </v-card-title>
      <v-list>
        <v-list-item
          class="unchecked-vehicle"
          v-for="(vehicle, index) in vehicles"
          :key="index"
        >
          <v-list-item-content
            class="clicked-event-info"
          >
            {{ vehicle.reg }} -
            {{ vehicle.workers }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'VehicleChecks',
  props: [
    'today',
    'date',
  ],
  data() {
    return {
      dialog: true,
      vehicles: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeVehicleChecks');
    },
    getChecks() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/vehicles/getChecks.json?token=${this.token}`, postData)
        .then((response) => {
          this.vehicles = response.data;
        });
    },
  },
  mounted() {
    this.getChecks();
  },
};
</script>

<style lang="scss">
  .unchecked-vehicle {
    border: 1px solid #333;
    padding: 5px;
    margin: 5px 20px;
    border-radius: 3px;
  }
</style>
