<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-card-title>
                  {{ holiday.worker.name }} is unavailable<br />
                  From {{ holiday.date_start | prettyDate }}
                  until {{ holiday.date_end | prettyDate }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <p class="pl-4">{{ holiday.description }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updateHoliday"
                    small
                    color="orange darken-2"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Update
                  </v-btn>
                  &nbsp;
                  <v-dialog
                    v-model="dialog"
                    width="900"
                    class="pt-3 pb-3"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red darken-2"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>
                          mdi-delete
                        </v-icon>
                        Delete
                      </v-btn>
                    </template>
                    <v-card class="pt-6 pb-4">
                      <v-card-text>
                        Are you sure you want to delete this holiday? This action cannot
                        be undone.
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="red darken-2"
                          @click="deleteHoliday"
                        >
                          <v-icon left>
                            mdi-delete
                          </v-icon>
                          Confirm delete
                        </v-btn>
                        <v-btn
                          small
                          color="blue-grey lighten-4"
                          @click="dialog = false"
                        >
                          <v-icon left>
                            mdi-close
                          </v-icon>
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="editDialog"
                    width="900"
                    class="pt-3 pb-3"
                  >
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <v-card-title>
                                <v-card-title>
                                  {{ holiday.worker.name }} is unavailable<br />
                                  From {{ dates[0] | prettyDate }}
                                  until {{ dates[1] | prettyDate }}
                                </v-card-title>
                              </v-card-title>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                            >
                              <sorted-range-date-picker
                                :maintenanceRecord="holiday"
                                @dateChanged="setDates"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                            >
                              <time-start-end-picker
                                v-if="dates"
                                :initialStart="holiday.start"
                                :initialEnd="holiday.end"
                                @startTimePicked="startTimePicked"
                                @endTimePicked="endTimePicked"
                              />
                              <v-textarea
                                v-model="description"
                                solo
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              class="text-right"
                            >
                              <v-btn
                                @click="saveChanges"
                                small
                                color="orange darken-1"
                              >
                                <v-icon left>
                                  mdi-pencil
                                </v-icon>
                                Save
                              </v-btn>
                              &nbsp;
                              <v-btn
                                small
                                color="blue-grey lighten-4"
                                @click="abandonChanges"
                              >
                                <v-icon left>
                                  mdi-close
                                </v-icon>
                                Abandon Changes
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    small
                    color="blue-grey lighten-4"
                    @click="$store.commit('setWorkerUnavailable', 0)"
                  >
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Close
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TimeStartEndPicker from '@/components/Tools/TimeStartEndPicker.vue';
import SortedRangeDatePicker from '@/components/Tools/SortedRangeDatePicker.vue';
import { format } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'ViewHoliday',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      dates: [],
      description: '',
      timeStart: '08:00',
      timeEnd: '18:00',
      setCount: 0,
      dialog: false,
      editDialog: false,
      holiday: {
        worker: {
          name: '',
        },
      },
    };
  },
  components: {
    TimeStartEndPicker,
    SortedRangeDatePicker,
  },
  methods: {
    getHoliday() {
      const holidayId = this.$store.state.selectedWorkerUnavailableId;
      axios.get(`/workerUnavailableDates/fetch/${holidayId}.json?token=${this.token}`)
        .then((response) => {
          this.holiday = response.data;
          this.holiday.start = this.holiday.date_start;
          this.holiday.end = this.holiday.date_end;
          this.setInitialValues();
        });
    },
    deleteHoliday() {
      const holidayId = this.$store.state.selectedWorkerUnavailableId;
      axios.post(`/workerUnavailableDates/remove.json?token=${this.token}`, holidayId)
        .then(() => {
          this.dialog = false;
          this.$store.commit('setWorkerUnavailable', 0);
          this.$store.commit('jobAdded');
          this.$store.dispatch('refreshWorkers');
        });
    },
    updateHoliday() {
      this.editDialog = true;
    },
    setDates(value) {
      if (this.setCount === 0) {
        this.setCount = 1;
        return;
      }
      const sortedDates = [];
      if (value[0] && !value[1]) {
        sortedDates.push(value[0]);
        sortedDates.push(value[0]);
      }
      if (value[0] && value[1] && new Date(value[0]).getTime() < new Date(value[1]).getTime()) {
        sortedDates.push(value[0]);
        sortedDates.push(value[1]);
      }
      if (value[0] && value[1] && new Date(value[0]).getTime() > new Date(value[1]).getTime()) {
        sortedDates.push(value[1]);
        sortedDates.push(value[0]);
      }
      this.dates = sortedDates;
    },
    startTimePicked(value) {
      this.timeStart = value;
    },
    endTimePicked(value) {
      this.timeEnd = value;
    },
    setInitialValues() {
      this.dates.push(format(new Date(this.holiday.date_start), 'YYY-MM-dd'));
      this.dates.push(format(new Date(this.holiday.date_end), 'YYY-MM-dd'));
      this.timeStart = format(new Date(this.holiday.date_start), 'HH:mm');
      this.timeEnd = format(new Date(this.holiday.date_end), 'HH:mm');
      this.description = this.holiday.description;
    },
    saveChanges() {
      const postData = {};
      postData.record = this.holiday.id;
      postData.worker = this.holiday.worker.id;
      postData.dates = this.dates;
      postData.timeStart = this.timeStart;
      postData.timeEnd = this.timeEnd;
      postData.description = this.description;
      postData.timeOffset = new Date().getTimezoneOffset();
      axios.post(`/workerUnavailableDates/updateHoliday.json?token=${this.token}`, postData)
        .then(() => {
          this.$store.dispatch('refreshWorkers');
          this.$store.commit('setWorkerUnavailable');
          this.editDialog = false;
        });
    },
    abandonChanges() {
      this.editDialog = false;
    },
  },
  mounted() {
    this.getHoliday();
  },
};
</script>
