<template>
  <div>
    <div class="text-center">
      <v-icon
        title="Client Search"
        color="orange darken-2"
        @click="setSearchType(1)"
      >
        mdi-account
      </v-icon>
      &nbsp;
      <v-icon
        title="Job Number Search"
        color="orange darken-2"
        @click="setSearchType(2)"
      >
        mdi-numeric
      </v-icon>
      &nbsp;
      <v-icon
        title="Job Site Search"
        color="orange darken-2"
        @click="setSearchType(3)"
      >
        mdi-map-marker
      </v-icon>
      &nbsp;
      <v-icon
        title="Date Ascending"
        color="orange darken-2"
        @click="$store.commit('setJobOrder', 'ascending')"
      >
        mdi-sort-calendar-ascending
      </v-icon>
      &nbsp;
      <v-icon
        title="Date Descending"
        color="orange darken-2"
        @click="$store.commit('setJobOrder', 'descending')"
      >
        mdi-sort-calendar-descending
      </v-icon>
      &nbsp;
      <v-icon
        title="Clear Search"
        color="orange darken-2"
        @click="clearSearch"
      >
        mdi-close-box-outline
      </v-icon>
    </div>
    <v-text-field
      v-if="type === 1"
      v-model="clientSearch"
      class="mt-2"
      placeholder="Seach Job By Client"
      prepend-inner-icon="mdi-magnify"
      solo-inverted
      clearable
      @click:clear="clientSearch = ''"
      dense
    ></v-text-field>
    <v-text-field
      v-if="type === 2"
      v-model="numberSearch"
      class="mt-2"
      placeholder="Seach Job By Number"
      prepend-inner-icon="mdi-magnify"
      solo-inverted
      clearable
      @clickL:clear="numberSearch = ''"
      dense
    ></v-text-field>
    <v-text-field
      v-if="type === 3"
      v-model="siteSearch"
      class="mt-2"
      placeholder="Seach Job By Site"
      prepend-inner-icon="mdi-magnify"
      solo-inverted
      clearable
      @clickL:clear="siteSearch = ''"
      dense
    ></v-text-field>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SearchJobs',
  data() {
    return {
      type: 1,
      date: null,
      menu: false,
      clientSearch: '',
      numberSearch: '',
      dateSearch: '',
      siteSearch: '',
    };
  },
  methods: {
    setSearchType(type) {
      this.type = type;
    },
    clearSearch() {
      this.type = 1;
      this.$store.commit('setJobClientSearch', '');
      this.$store.commit('setJobNumberSearch', '');
    },
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date() {
      this.$store.commit('setJobDateSearch', this.date);
    },
    clientSearch() {
      this.$store.commit('setJobClientSearch', this.clientSearch);
      if (this.clientSearch) {
        if (this.clientSearch.length > 2) {
          axios.post(`/quotations/searchByClient.json?token=${this.token}`, this.clientSearch)
            .then((response) => {
              this.$store.commit('setFoundJobs', response.data);
            });
        } else {
          this.$store.commit('setFoundJobs', []);
        }
      } else {
        this.$store.commit('setFoundJobs', []);
      }
    },
    numberSearch() {
      this.$store.commit('setJobNumberSearch', this.numberSearch);
      if (this.numberSearch) {
        if (this.numberSearch.length > 3) {
          axios.post(`/quotations/searchByNumber.json?token=${this.token}`, this.numberSearch)
            .then((response) => {
              this.$store.commit('setFoundJobs', response.data);
            });
        } else {
          this.$store.commit('setFoundJobs', []);
        }
      } else {
        this.$store.commit('setFoundJobs', []);
      }
    },
    siteSearch() {
      this.$store.commit('setJobSiteSearch', this.siteSearch);
      if (this.siteSearch) {
        if (this.siteSearch.length > 3) {
          axios.post(`/quotations/searchBySite.json?token=${this.token}`, this.siteSearch)
            .then((response) => {
              this.$store.commit('setFoundJobs', response.data);
            });
        } else {
          this.$store.commit('setFoundJobs', []);
        }
      } else {
        this.$store.commit('setFoundJobs', []);
      }
    },
  },
};
</script>
