<template>
  <div>
    <v-row class="pt-8">
      <v-col cols="8" class="pl-5">
        <span>Key:</span>&nbsp;
        <span class="key job">Planned Job</span>
        <span class="key job-on-hold">Job On Hold</span>
        <span class="key maintenance">Maintenance</span>
        <span class="key note">Note</span>
      </v-col>
    </v-row>
    <v-row class="pt-2 fill-height">
      <v-col>
        <v-sheet height="1200" width="99%" class="pl-2">
          <v-calendar
            class="fixed-height-calendar"
            ref="calendar"
            color="primary"
            type="month"
            :events="events"
            @click:event="showEvent"
            @click:more="viewMore"
            :start="date"
            :weekdays="[1,2,3,4,5,6,0]"
          >
            <template v-slot:event="{ event }">
              <div class="day-night-icon-holder">
                <span class="month-event-text">{{ event.name }}</span>
                <v-icon
                  v-if="event.eventType === 'work' && event.eventTime === 'day'"
                  class="day-night-indicator">mdi-weather-sunny</v-icon>
                <v-icon
                  v-if="event.eventType === 'work' && event.eventTime === 'night'"
                  class="day-night-indicator">mdi-moon-waning-crescent</v-icon>
                <v-icon
                  v-if="event.eventType === 'notes'"
                  class="day-night-indicator">mdi-pencil</v-icon>
                <v-icon
                  v-if="event.eventType === 'maintenance'"
                  class="day-night-indicator">mdi-wrench-outline</v-icon>
                <v-icon
                  v-if="event.eventType === 'holiday'"
                  class="day-night-indicator">mdi-umbrella-beach-outline</v-icon>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="900px"
    >
      <v-card class="clearfix">
        <v-card-title>All events for {{ clickedDate | prettyDate }}</v-card-title>
        <v-list>
          <v-list-item
            v-for="(clickedEvent, index) in clickedEvents"
            :key="index">
            <v-list-item-content
              class="clicked-event-info"
              :class="[ { onHold: clickedEvent.onHold === true }, clickedEvent.eventType]"
              @click="showClickedEvent(clickedEvent)"
            >
              <v-icon
                  v-if="clickedEvent.eventType === 'work' && clickedEvent.eventTime === 'day'"
                  class="day-night-indicator">mdi-weather-sunny</v-icon>
                <v-icon
                  v-if="clickedEvent.eventType === 'work' && clickedEvent.eventTime === 'night'"
                  class="day-night-indicator">mdi-moon-waning-crescent</v-icon>
                <v-icon
                  v-if="clickedEvent.eventType === 'notes'"
                  class="day-night-indicator">mdi-pencil</v-icon>
                <v-icon
                  v-if="clickedEvent.eventType === 'maintenance'"
                  class="day-night-indicator">mdi-wrench-outline</v-icon>
                <v-icon
                  v-if="clickedEvent.eventType === 'holiday'"
                  class="day-night-indicator">mdi-umbrella-beach-outline</v-icon>
              <v-list-item-title>{{ clickedEvent.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ clickedEvent.client }}</v-list-item-subtitle>
              <p class="text-body-2">{{ clickedEvent.address }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions class="float-right">
          <v-btn
            color="blue-grey lighten-4"
            small
            @click="dialog = false"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format, startOfWeek } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'MonthlyCalendar',
  computed: {
    token() {
      return this.$store.state.token;
    },
    date() {
      const today = this.$store.state.date;
      const firstDayOfTheWeek = startOfWeek(new Date(today));
      return firstDayOfTheWeek;
    },
    notes() {
      return this.$store.state.notes;
    },
    vehicles() {
      return this.$store.state.vehicles;
    },
  },
  watch: {
    date() {
      this.fetchEvents();
    },
    notes() {
      this.filterEvents();
    },
    vehicles() {
      this.filterEvents();
    },
  },
  data() {
    return {
      events: [],
      dialog: false,
      clickedDate: null,
      clickedEvents: [],
      plannedJobs: [],
    };
  },
  methods: {
    viewMore({ date }) {
      this.dialog = true;
      this.clickedDate = date;
      this.filterEvents();
    },
    filterEvents() {
      this.clickedEvents = this.events.filter((event) => event.start === this.clickedDate);
    },
    showEvent({ nativeEvent, event }) {
      if (event.plannedJobId > 0) this.$store.commit('selectPlannedJob', event.plannedJobId);
      if (event.maintenanceId > 0) this.$store.commit('selectVehicleMaintence', event.maintenanceId);
      if (event.noteId > 0) this.$store.commit('selectNote', event.noteId);
      if (event.holidayId > 0) this.$store.commit('setWorkerUnavailable', event.holidayId);
      nativeEvent.stopPropagation();
    },
    showClickedEvent(ce) {
      if (ce.plannedJobId > 0) this.$store.commit('selectPlannedJob', ce.plannedJobId);
      if (ce.maintenanceId > 0) this.$store.commit('selectVehicleMaintence', ce.maintenanceId);
      if (ce.noteId > 0) this.$store.commit('selectNote', ce.noteId);
      if (ce.holidayId > 0) this.$store.commit('setWorkerUnavailable', ce.holidayId);
    },
    formatEvents() {
      const tempPlannedJobs = this.plannedJobs;
      const tempVehicles = this.$store.state.vehicles;
      const tempNotes = this.$store.state.notes;
      const tempWorkers = this.$store.state.workers;
      const events = [];
      for (let i = 0; i < tempPlannedJobs.length; i += 1) {
        if (tempPlannedJobs[i].eventTime === 'day') {
          const vehicle = tempVehicles.filter(
            (vcle) => vcle.reg === tempPlannedJobs[i].category,
          )[0];
          if (vehicle) {
            let color = 'orange lighten-2';
            if (tempPlannedJobs[i].onHold === true) {
              color = 'blue';
            }
            if (tempPlannedJobs[i].complete === true) {
              color = 'red';
            }
            events.push({
              address: tempPlannedJobs[i].address,
              category: vehicle.reg,
              client: tempPlannedJobs[i].client,
              color,
              end: `${tempPlannedJobs[i].end}`,
              eventTime: 'day',
              eventType: 'work',
              jobId: tempPlannedJobs[i].jobId,
              maintenanceId: 0,
              name: tempPlannedJobs[i].name,
              noteId: 0,
              onHold: tempPlannedJobs[i].onHold,
              plannedJobId: tempPlannedJobs[i].plannedJobId,
              start: `${tempPlannedJobs[i].start}`,
              uniqueId: `job_${tempPlannedJobs[i].plannedJobId}`,
            });
          }
        }
      }
      for (let i = 0; i < tempPlannedJobs.length; i += 1) {
        if (!tempPlannedJobs[i].day) {
          const vehicle = tempVehicles.filter(
            (vcle) => vcle.reg === tempPlannedJobs[i].category,
          )[0];
          if (vehicle) {
            let color = 'orange lighten-2';
            if (tempPlannedJobs[i].onHold === true) {
              color = 'blue';
            }
            if (tempPlannedJobs[i].complete === true) {
              color = 'red';
            }
            events.push({
              address: tempPlannedJobs[i].address,
              category: vehicle.reg,
              client: tempPlannedJobs[i].client,
              color,
              end: `${tempPlannedJobs[i].end}`,
              eventTime: 'night',
              eventType: 'work',
              jobId: tempPlannedJobs[i].jobId,
              maintenanceId: 0,
              name: tempPlannedJobs[i].name,
              noteId: 0,
              onHold: tempPlannedJobs[i].onHold,
              plannedJobId: tempPlannedJobs[i].plannedJobId,
              start: `${tempPlannedJobs[i].start}`,
              uniqueId: `job_${tempPlannedJobs[i].plannedJobId}`,
            });
          }
        }
      }
      for (let i = 0; i < tempVehicles.length; i += 1) {
        if (tempVehicles[i].unavailableDates) {
          for (let x = 0; x < tempVehicles[i].unavailableDates.length; x += 1) {
            const startTime = format(
              new Date(tempVehicles[i].unavailableDates[x].start), 'yyy-MM-dd',
            );
            const timeEnd = format(new Date(tempVehicles[i].unavailableDates[x].end), 'yyy-MM-dd');
            events.push({
              name: `${tempVehicles[i].reg} out of service -
                ${tempVehicles[i].unavailableDates[x].description}`,
              start: startTime,
              end: timeEnd,
              category: tempVehicles[i].reg,
              jobId: tempVehicles[i].reg,
              plannedJobId: 0,
              maintenanceId: tempVehicles[i].unavailableDates[x].id,
              noteId: 0,
              color: 'red darken-2',
              eventType: 'maintenance',
              onHold: false,
              uniqueId: `veh_${tempVehicles[i].unavailableDates[x].id}`,
              eventTime: 'any',
            });
          }
        }
      }
      for (let i = 0; i < tempNotes.length; i += 1) {
        const vehicle = tempVehicles.filter((vcle) => vcle.id === tempNotes[i].vehicle_id)[0];
        if (vehicle) {
          const startTime = format(new Date(tempNotes[i].date), 'yyy-MM-dd');
          const timeEnd = format(new Date(tempNotes[i].date), 'yyy-MM-dd');
          events.push({
            name: tempNotes[i].note,
            start: startTime,
            end: timeEnd,
            category: vehicle.reg,
            jobId: 0,
            plannedJobId: 0,
            maintenanceId: 0,
            noteId: tempNotes[i].id,
            color: 'cyan darken-2',
            eventType: 'notes',
            onHold: false,
            uniqueId: `note_${tempNotes[i].id}`,
            eventTime: 'any',
          });
        }
      }
      for (let i = 0; i < tempWorkers.length; i += 1) {
        const vehicle = tempVehicles.filter((vcle) => vcle.id === tempWorkers[i].vehicle_id)[0];
        if (vehicle) {
          if (tempWorkers[i].unavailableDates) {
            for (let x = 0; x < tempWorkers[i].unavailableDates.length; x += 1) {
              const startTime = format(new Date(tempWorkers[i].unavailableDates[x].start), 'yyy-MM-dd');
              const timeEnd = format(new Date(tempWorkers[i].unavailableDates[x].end), 'yyy-MM-dd');
              events.push({
                name: `${tempWorkers[i].name} unavailable - ${tempWorkers[i].unavailableDates[x].description}`,
                start: startTime,
                end: timeEnd,
                category: vehicle.reg,
                jobId: 0,
                plannedJobId: 0,
                maintenanceId: 0,
                noteId: 0,
                holidayId: tempWorkers[i].unavailableDates[x].id,
                color: 'lime darken-2',
                eventType: 'holiday',
                onHold: false,
                uniqueId: `hol_${tempWorkers[i].unavailableDates[x].id}`,
                eventTime: 'any',
              });
            }
          }
        }
      }
      this.events = events;
    },
    fetchEvents() {
      this.events = [];
      const date = format(new Date(this.date), 'yyyy-MM-dd');
      axios.get(`/plannedJobs/getMonthEvents.json?token=${this.token}&date=${date}`)
        .then((response) => {
          this.plannedJobs = response.data.events;
          // this.$store.dispatch('setMonthEvents', response.data.events);
          this.formatEvents();
        });
    },
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>

<style lang="scss">
  .fixed-height-calendar .v-calendar-weekly__week {
    max-height: 110px!important;
  }
  .clicked-event-info {
    display: inline-block;
    margin-bottom: 2px;
    padding: 5px;
    border-radius: 3px;
    cursor:pointer;
    position: relative;

    .day-night-indicator {
      position: absolute;
      top: 2px;
      right: 2px;
    }

    &.work {
      background-color: #FFB74D;
      &.onHold {
        background-color: #2196F3;
      }
    }
    &.maintenance {
      background-color: #D32F2F;
    }
    &.notes {
      background-color: #00BCD4;
    }
  }
  .day-night-icon-holder {
    position:relative;
    padding: 1px;

    .month-event-text {
      display: inline-block;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .day-night-indicator {
      position:absolute;
      top: 1px;
      right: 1px;
      font-size: 16px;
    }
  }

</style>
