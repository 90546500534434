<template>
  <div>
    <v-dialog
        v-model="showDialog"
        max-width="900px"
      >
        <v-card class="clearfix">
          <v-card-title>All on hold jobs</v-card-title>
          <v-list>
            <v-list-item
              class="on-hold-job-item"
              v-for="(job, index) in onHoldJobs"
              :key="index">
              <v-list-item-content
                class="clicked-event-info"
              >
                <v-icon
                  v-if="job.day === true"
                  class="day-night-indicator">
                  mdi-weather-sunny
                </v-icon>
                <v-icon
                  v-else
                  class="day-night-indicator">
                  mdi-moon-waning-crescent
                </v-icon>
                <v-list-item-title>{{ job.jobNumber }}</v-list-item-title>
                <v-list-item-subtitle>{{ job.clientName }}</v-list-item-subtitle>
                <p class="text-body-2">{{ job.jobAddress }}</p>
                <p class="pt-3">
                  <v-btn
                    small
                    color="orange accent-2"
                    :href="`${$store.state.domain}/job_cards/jobCard/${job.jobCardId}`"
                    target="_blank"
                  >
                    <v-icon left>
                      mdi-card-bulleted-outline
                    </v-icon>
                    Job Card
                  </v-btn>
                  &nbsp;
                  <v-btn
                    small
                    color="orange accent-2"
                    @click="selectedPlannedJob = job.id"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Update
                  </v-btn>
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions class="float-right">
            <v-btn
                small
                color="blue-grey lighten-4"
                @click="close"
              >
                <v-icon left>
                  mdi-close
                </v-icon>
                Close
              </v-btn>
          </v-card-actions>
        </v-card>
        <edit-job
          v-if="selectedPlannedJob > 0"
          :plannedJobId="selectedPlannedJob"
          @viewPlannedJob="selectedPlannedJob = 0"
        />
      </v-dialog>
  </div>
</template>

<script>
import EditJob from '@/components/Jobs/SelectedPlannedJob/Edit.vue';
import axios from '../../axios';

export default {
  name: 'OnHoldJobs',
  components: {
    EditJob,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      showDialog: true,
      onHoldJobs: [],
      selectedPlannedJob: 0,
    };
  },
  methods: {
    getOnHoldJobs() {
      axios.get(`/plannedJobs/getOnHoldJobs.json?token=${this.token}`)
        .then((response) => {
          this.onHoldJobs = response.data;
        });
    },
    close() {
      this.$emit('closeOnHoldJobs');
    },
  },
  mounted() {
    this.getOnHoldJobs();
  },
};
</script>

<style lang="scss">
  .clearfix::after {
    content: '';
    display: table;
    clear: both;
  }
  .on-hold-job-item {
    margin: 6px;
    border-radius: 3px;
    border: 1px solid #CCC;
  }
</style>
