<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-card-title>
                  Survey for {{ survey.customer }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <p class="pl-4">{{ survey.date | prettyDateTime }}</p>
                <p class="pl-4">{{ survey.address }}</p>
                <p class="pl-4">To be surveyed by {{ survey.surveyed_by }}</p>
                <p class="pl-4">
                    <v-btn
                        target="_blank"
                        small
                        color="orange darken-2"
                        :href="`${$store.state.domain}/surveys/view/${survey.pal_survey_id}`">
                        View Survey
                    </v-btn>&nbsp;
                    <v-btn
                        target="_blank"
                        small
                        color="orange darken-2"
                        :href="`${$store.state.domain}/surveys/edit/${survey.pal_survey_id}`">
                        Complete Survey
                    </v-btn>&nbsp;
                    <v-btn
                        small
                        color="blue-grey lighten-4"
                        @click="$store.commit('setSurvey', 0)"
                    >
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Close
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ViewSurvey',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      dialog: false,
      survey: {},
    };
  },
  methods: {
    getSurvey() {
      const surveyId = this.$store.state.selectedSurveyId;
      axios.get(`/surveys/fetch/${surveyId}.json?token=${this.token}`)
        .then((response) => {
          this.survey = response.data;
        });
    },
  },
  mounted() {
    this.getSurvey();
  },
};
</script>
