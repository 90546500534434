<template>
  <calendar></calendar>
</template>

<script>
import Calendar from '@/components/PlanJobs/Calendar.vue';

export default {
  name: 'Plan',
  components: {
    Calendar,
  },
};
</script>
