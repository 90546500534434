<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              ></v-col>
                <v-card-title>
                  Add a note for {{ noteToSave }} on {{ date | prettyDate }}
                </v-card-title>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  solo
                  label="Note"
                  v-model="noteText"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="saveNote"
                    small
                    color="orange darken-1"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Save
                  </v-btn>
                  <v-btn
                    small
                    color="blue-grey lighten-4"
                    @click="abandonChanges"
                  >
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Abandon Note
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'NoteToSave',
  props: [
    'noteToSave',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    date() {
      const date = this.$store.state.initialDate[0];
      return date;
    },
  },
  data() {
    return {
      noteText: '',
    };
  },
  methods: {
    saveNote() {
      const postData = {};
      postData.date = this.date;
      postData.vehicle = this.noteToSave;
      postData.note = this.noteText;
      axios.post(`/notes/add.json?token=${this.token}`, postData)
        .then(() => {
          this.$store.dispatch('refreshNotes');
          this.$store.commit('jobAdded');
          this.$emit('abandonChanges');
        });
    },
    abandonChanges() {
      this.$emit('abandonChanges');
    },
  },
};
</script>
